<template>
	<el-form ref="form" :model="form" label-width="220px" class="form" disabled>
		<el-form-item label="公司名称">
			<el-input v-model="form.company_name" />
		</el-form-item>
		<el-form-item label="统一社会信用代码或营业执照号">
			<el-input v-model="form.license_code" />
		</el-form-item>
		<el-form-item label="营业执照(副本)">
			<el-upload class="avatar-uploader" :action="picUrl" :show-file-list="false"
				:on-success="handleFileSuccess"
				 :before-upload="beforeAvatarUpload"
				 accept=".png,.jpg">
				<img v-if="imageUrl" :src="imageUrl" class="avatar">
				<i v-else class="el-icon-plus avatar-uploader-icon" />
			</el-upload>
			<div class="tishinews">
				小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					company_name: '',
					license_code: '',
					license: ''
				},
				imageUrl: '',
				picUrl:''
			}
		},
		created() {
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			handleFileSuccess(res, file) {
				if (res.error == 'success') {
					this.form.license = res.imgurl
					this.imageUrl = URL.createObjectURL(file.raw)
					this.$message.success('上传成功');
				} else {
					this.$message.error(res.msg);
				}
			}
		},
		props: ['transForm'],
		watch: {
			transForm(val) {
				this.form = val
				this.imageUrl = this.$store.state.IMG_URL + '/' + val.license
			}
		}
	}
</script>

<style>
	.tishinews{
		color: #666ee8;
	}
</style>
