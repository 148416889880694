<template>
	<el-form ref="form" :model="form" label-width="106px" class="form" disabled>
		<el-form-item label="企业类型" class="qiye">
			<el-select v-model="form.industry_id" >
				<el-option-group v-for="item in industryList" :key="item.id" >
					<el-option v-for="items in item.child" :key="items.id" :label="items.title" :value="form.industry_id" />
				</el-option-group>
			</el-select>
		</el-form-item>
		<el-form-item label="主营行业">
			<el-select v-model="form.typeid" class="zhuying" >
				<el-option v-for="item in infoList" :key="item.id" :label="item.catname" :value="form.typeid" />
			</el-select>
		</el-form-item>
		<el-form-item v-for="(item,index) in form.zycp" :key="index" :label="'主营产品'+(index+1)">
			<el-input class="small-input" v-model="form.zycp[index]" />
			<el-button v-if="index!=0" @click="delZycp(index)">删除</el-button>
		</el-form-item>
		<el-form-item>
			<el-button @click="addZycp">新增主营产品</el-button>
		</el-form-item>
		<el-form-item label="经营地址">
			<area-select v-model="addressData" type="text" :data="pcaa" :level="2" disabled
				@change="handleAddressChange" />
		</el-form-item>
		<el-form-item label="详细地址">
			<el-input v-model="form.address" />
		</el-form-item>
		<el-form-item>
			<div class="tip">
				<p>注：如企业信息有变动，请联系客服修改企业信息。</p>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
	import {
		pca,
		pcaa
	} from 'area-data'
	import 'vue-area-linkage/dist/index.css'
	export default {
		data() {
			return {
				form: {
					industry_id: '',
					typeid: '',
					zycp: [''],
					sheng: '',
					shi: '',
					xian: '',
					address: ''
				},
				industryList: [],
				infoList: [],
				pca,
				pcaa,
				addressData: [],
			}
		},
		beforeCreate() {
			
			// var inu = this.industry_id
			// var typ = this.typeid 
			// this.$axios.post('/getcompany').then(data => {
			// 	this.industry_id = data.industry_id
			// 	this.typeid = data.typeid
				
			// })
			this.$axios.post('/getindustry').then(data => {
				this.industryList = data.industry
				this.infoList = data.info
				// this.industryList.forEach(function(my,ind,arr){
				// 	my.child.forEach((i) =>{
				// 		console.log(i)
				// 		if(inu == i.id){
				// 			$(".qiye>div>div>div>input").val(i.title)
				// 		}
				// 	})
					
				// })
				//主营产品
				// this.infoList.forEach((idtyp)=>{
				// 	console.log(idtyp.id)
				// 	console.log(idtyp.catname)
				// 	console.log(typ)
				// 	if(typ == idtyp.id){
				// 		$(".zhuying>div>input").val(idtyp.catname)
				// 	}
				// })
			})
			
		},
		methods: {
			addZycp() {
				this.form.zycp.push('')
			},
			delZycp(index) {
				this.form.zycp.splice(index, 1)
			},
			handleAddressChange(data) {
				this.form.sheng = data[0]
				this.form.shi = data[1]
				this.form.xian = data[2]
			}
		},
		props: ['transForm'],
		watch: {
			transForm(val) {
				this.form = val
				this.addressData = [val.sheng, val.shi, val.xian]
			}
		}
	}
</script>

<style scoped>
	.small-input {
		width: 300px;
		margin-right: 10px;
	}

	.form>>>.area-select {
		height: 40px;
		line-height: 26px;
		margin-left: 0;
	}

	.tip {
		padding: 8px 16px;
		background: #ecf8ff;
		border-radius: 4px;
		border-left: 5px solid #50bfff;
		margin: 20px 0;
	}

	.tip p {
		font-size: 14px;
		color: #5e6d82;
		line-height: 1.5;
	}
</style>
