<template>
	<div class="wrap">
		<div class="tit">企业资料</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="基本信息" name="1">
				<basic :transForm="transForm1" />
			</el-tab-pane>
			<el-tab-pane label="企业认证" name="2">
				<ec :transForm="transForm2" />
			</el-tab-pane>
			<el-tab-pane label="身份认证" name="3">
				<ia :transForm="transForm3" />
			</el-tab-pane>
			<el-tab-pane label="银行信息" name="4">
				<bank :transForm="transForm4" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import Basic from '@/components/User/Basic'
	import Ec from '@/components/User/Ec'
	import Ia from '@/components/User/Ia'
	import Bank from '@/components/User/Bank'
	export default {
		data() {
			return {
				activeName: this.$route.params.id || '1',
				transForm1: {},
				transForm2: {},
				transForm3: {},
				transForm4: {}
			}
		},
		created() {
			this.$axios.post('/getcompany',{user_id:this.$store.state.USER_ID}).then(data => {
				this.transForm1 = {
					industry_id: data.industry_id,
					typeid: data.typeid,
					zycp: data.zycp,
					sheng: data.sheng,
					shi: data.shi,
					xian: data.xian,
					address: data.address,
					typeid : data.typeid
				}
				this.transForm2 = {
					company_name: data.company_name,
					license_code: data.license_code,
					license: data.license
				}
				this.transForm3 = {
					cperson: data.cperson,
					cphone: data.cphone,
					cphoto: data.cphoto,
					cphoto_back:data.cphoto_back
				}
				this.transForm4 = {
					//农业银行 北城支行 
					bankname: this.$route.query.BankName,
					AccountName:this.$route.query.AccountName,
					bankcode: this.$route.query.Account,
					rate:this.$route.query.rate
				}
			})
			this.$axios.get('vertyCode?seller_id='+this.$store.state.SELLER_ID).then(data => {
				this.transForm4 = {
					bankname: data.BankName,
					bankcode: data.Account,
					AccountName:data.AccountName,
					rate:Number(data.rate) * 100 +'%'
				}
			})
		},
		methods: {
			handleClick({
				name
			}) {
				this.$router.push(`/user/${name}`)
			}
		},
		components: {
			Basic,
			Ec,
			Ia,
			Bank
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	body{
	  min-width:1920px;
	}
	.tit {
		height: 77px;
		border-bottom: 1PX solid #ebebeb;
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 700;
		padding: 0 28px;
	}

	.tit::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0f69dc;
		margin-right: 11px;
	}

	.wrap>>>.el-tabs__header {
		margin: 31px 32px 0;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		padding: 0 95px;
	}

	.wrap>>>.el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	.wrap>>>.el-tabs__active-bar {
		height: 4px;
		background: #0f69dc;
	}

	.wrap>>>.el-tabs__item {
		height: 70px;
		line-height: 70px;
	}

	.wrap>>>.el-tabs__content {
		overflow: initial;
	}

	.el-form {
		margin: 64px 900px 0 0;
	}

	.form {
		overflow: hidden;
		padding-bottom: 150px;
	}

	.form>>>.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.form>>>.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.form>>>.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.form>>>.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.tip{
		
	}
</style>
