<template>
	<el-form ref="form" :model="form" label-width="106px" class="form" disabled>
		<el-form-item label="注册人姓名">
			<el-input v-model="form.cperson" />
		</el-form-item>
		<el-form-item label="联系电话">
			<el-input v-model="form.cphone" />
		</el-form-item>
		<div class="card">
			<el-form-item label="身份证人像面">
				<el-upload class="avatar-uploader" :action="picUrl" :show-file-list="false"
					:on-success="handleFileSuccess"
					 :before-upload="beforeAvatarUploadA"
					 accept=".png,.jpg">
					<img v-if="imageUrl" :src="imageUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon" />
				</el-upload>
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
				</div>
			</el-form-item>
			<el-form-item label="身份证国徽面">
				<el-upload class="avatar-uploader" :action="picUrl" :show-file-list="false"
					:on-success="handleFileSuccessB" 
					:before-upload="beforeAvatarUploadB"
					accept=".png,.jpg">
					<img v-if="imageUrlB" :src="imageUrlB" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon" />
				</el-upload>
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
				</div>
			</el-form-item>
		</div>
	</el-form>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					cperson: '',
					cphone: '',
					cphoto: ''
				},
				imageUrl: '',
				imageUrlB: '',
				picUrl:''
			}
		},
		created() {
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			beforeAvatarUploadA(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			handleFileSuccess(res, file) {
				if (res.code == 200) {
					this.form.cphoto = res.imgurl
					this.imageUrl = URL.createObjectURL(file.raw)
					this.$message.success('上传成功');
				} else {
					this.$message.error(res.msg);
				}
			},
			beforeAvatarUploadB(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			handleFileSuccessB(res, file) {
				if (res.code == 200) {
					this.form.cphoto_back = res.imgurlB
					this.imageUrlB = URL.createObjectURL(file.raw)
					this.$message.success('上传成功');
				} else {
					this.$message.error(res.msg);
				}
			}
		},
		props: ['transForm'],
		watch: {
			transForm(val) {
				this.form = val
				this.imageUrl = this.$store.state.IMG_URL + '/' + val.cphoto
				this.imageUrlB = this.$store.state.IMG_URL+'/'+val.cphoto_back
			}
		}
	}
</script>

<style>
	.card{
		display: flex;
		justify-content: space-around;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
