<template>
	<el-form ref="form" :model="form" label-width="106px" class="form" disabled>
		<el-form-item label="名称">
			<el-input v-model="form.AccountName"/>
		</el-form-item>
		<el-form-item label="开户行">
			<el-input v-model="form.bankname"/>
		</el-form-item>
		<el-form-item label="对公账户">
			<el-input v-model="form.bankcode" />
		</el-form-item>
		<el-form-item label="手续费费率">
			<el-input v-model="form.rate" />
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					bankname: '',
					bankcode: '',
					AccountName:'',
					rate:''
				}
			}
		},
		props: ['transForm'],
		watch: {
			transForm(val) {
				this.form = val
			}
		}
	}
</script>

<style>
</style>
